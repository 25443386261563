import React from 'react';


import Layout from '../../components/mini/layout';
import SEO from '../../components/mini/seo';
import FAQ from '../../components/faq/faq';

import faqs from '../../components/faq/mini-faqs';

const SchoolFAQPage = () => {
  return (
    <Layout>
      <SEO title="THE MINI DRIVING EXPERIENCE | School FAQ" />
      <div className="faq-page deliveryfaq">
        <section className="hero__header">
          <div className="container">
            <div className="hero__header__content">
              <h1>FREQUENTLY ASKED QUESTIONS</h1>
              <p>Listed below are some common questions regarding driving programs offered by MINI. For additional help, please call us at <a href="tel:888-345-4269">888-345-4269.</a></p>
            </div>
          </div>
        </section>
        <section className="faq">
          <div className="container">
            <FAQ faqs={faqs} />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default SchoolFAQPage;
